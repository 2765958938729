<template>
  <b-link :to="to" class="picker-item">
    <b-card
      :class="cardClasses"
      body-class="d-flex align-items-center justify-content-center"
    >
      <div>
        <font-awesome-icon
          v-if="icon"
          :icon="[iconStyle ? iconStyle : 'fas', icon]"
          :class="iconClasses"
        />
        <b-img-lazy v-if="image" :src="image" fluid :alt="name" />
        <h4 v-if="!image" class="mb-0">{{ name }}</h4>
      </div>
    </b-card>
  </b-link>
</template>

<script>
export default {
  name: "PickerItem",
  props: {
    to: { required: true },
    icon: { type: String },
    iconStyle: { type: String },
    name: { type: String },
    size: { type: String },
    image: { type: String }
  },
  computed: {
    iconClasses() {
      switch (this.size) {
        case "sm":
          return "mb-2";
        case "xs":
          return "mb-1";
        default:
          return "mb-3";
      }
    },
    cardClasses() {
      let classes = "platter p-0 text-center " + this.size + " ";

      switch (this.size) {
        case "xs":
          classes += "m-1 ";
          break;
        default:
          classes += "m-1 m-sm-2";
          break;
      }

      return classes;
    }
  }
};
</script>

<style scoped lang="scss">
svg {
  font-size: 3.5em;
  transition: all 0.2s;

  @media (min-width: 576px) {
    font-size: 4em;
  }

  .sm & {
    font-size: 2.55em;
  }

  .xs & {
    font-size: 2.1em;
  }
}

a {
  text-decoration: none;
  transition: all 0.2s;
}

a:hover .card {
  transition: all 0.2s;
}

h4 {
  font-size: 0.9em;
  transition: all 0.2s;

  @media (min-width: 576px) {
    font-size: 1.35em;
  }

  .sm & {
    font-size: 0.9em;
  }

  .xs & {
    font-size: 0.9em;
  }
}

.card-body {
  padding: 10px;

  @media (min-width: 370px) {
    padding: 1.25rem;
  }

  .xs & {
    padding: 10px;
  }
}

.picker-item > div {
  height: 100%;
}
</style>